import Base from "../components/base";
import Seo from "../components/Seo";
import React from "react";
import {useSiteMetadata} from "../hooks/use-sitemeta";
import {StaticImage} from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";

const NotFound = ({pageContext}) => {
  const {siteMetadata} = useSiteMetadata();
  return (<Base>
    <Seo article image={siteMetadata.defaultImage}
         description={siteMetadata.description}
         title="Nicht gefunden"
         pathname={pageContext.slug}/>
    <Container fluid="xl" className="center text-center">
      <StaticImage src="../assets/404.png" alt={siteMetadata.title}
                   height={400} placeholder="tracedSvg"/>
      <div className="image-credits my-3"><a href="https://theoatmeal.com">Bild: The Oatmeal</a></div>
    </Container>
  </Base>)
}
export default NotFound;